import React, { Component } from 'react'
import styled from 'styled-components';
import { ColumnContainer, Gutter } from '../stylesheets/components';
import { Logo } from './';
import media from '../stylesheets/media';

const Column = styled.div`
  width: calc(25% - 10px);

  ${media.mobileLarge`
    width: 100%;
  `}
`;

const FooterDesc = styled.div`
  font-size:0.8em;
  color: #909090;
  line-height: 1.3;

  a {
    color: #909090;
    text-decoration: underline;
  }
`;

const BlackFooter = styled.div`
  background-color: black;
  padding: 20px 20px 80px;

  ${media.mobileLarge`
    width: calc(100% - 40px);
  `}
`;

const DesktopGutter = styled(Gutter)`
  ${media.mobileLarge`
    height: 10px;
  `}
`

export default class Footer extends Component {
  render() {
    return (
      <BlackFooter>
        <ColumnContainer>
          <Column>
            <Logo color="#777" />
            <DesktopGutter h={10} />
            <FooterDesc>
              Data + Feminism Lab<br />
              Department of Urban Studies and Planning<br />
              Massachusetts Institute of Technology
            </FooterDesc>
            <DesktopGutter h={40} />
            <svg width="43" height="23" viewBox="0 0 43 23" fill="none">
              <path fillRule="evenodd" clipRule="evenodd" d="M0 0.105469H4.6966V22.5199H0V0.105469Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M14.9704 0.105469H19.667V22.5199H14.9704V0.105469Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M7.49133 0.105469H12.1879V15.4935H7.49133V0.105469Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M22.6024 7.50684H27.299V22.5201H22.6024V7.50684Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M30.2344 7.50684H34.931V22.5201H30.2344V7.50684Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M30.2344 0H42.3817V4.19558H30.2344V0Z" fill="white" />
              <path fillRule="evenodd" clipRule="evenodd" d="M22.6024 0H27.299V4.19558H22.6024V0Z" fill="white" />
            </svg>
          </Column>
          <Column>
            <DesktopGutter h={80} />
            <FooterDesc>
              MIT 10-485M<br/>
              77 Massachusetts Avenue<br/>
              Cambridge, MA 02139 USA
            </FooterDesc>
          </Column>

          <Column>
            <DesktopGutter h={80} />
            <FooterDesc>
              For more information,<br/>
              <a href="mailto:dataplusfeminism@mit.edu">dataplusfeminism@mit.edu</a><br/><br/>
              <a href="https://accessibility.mit.edu/" target="_blank" rel="noopener noreferrer">Accessibility</a>
            </FooterDesc>
          </Column>
          <Column>
          </Column>
        </ColumnContainer>
      </BlackFooter>
    )
  }
}
