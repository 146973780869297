import Intro from './Intro';
import About from './About';
import People from './People';
import Projects from './Projects';
import Courses from './Courses';
import Events from './Events';
import EventShow from './EventShow';
import Publications from './Publications';
import Blog from './Blog';
import Links from './Links';

export {
  Intro,
  About,
  People,
  Projects,
  Courses,
  Publications,
  Events,
  EventShow,
  Blog,
  Links
}